<template>
    <div>
        <v-navigation-drawer
            v-model="drawer"
            app
            color="black"
            src="@/assets/sidebar.png"
            dark
        >
            <div class="logo-text white--text text-center mt-3">
                <h3>LUNTIAN</h3>
                <small>DESIGN & SOLUTIONS</small>
            </div>
            <div class="text-center mb-5 mt-5">
                <img src="@/assets/logo.png" alt="" height="70" />
            </div>
            <v-divider></v-divider>
            <v-list dense nav>
                <v-list-item-group v-model="page">
                    <v-list-item
                        v-for="(m, i) in menus"
                        :key="i"
                        link
                        :to="{name : m.link}"
                        >
                        <v-list-item-icon>
                            <v-icon>{{ m.icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{ m.text }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        link
                        :to="{name : 'Notifications'}"
                        >
                        <v-list-item-icon>
                            <v-badge
                                bordered
                                color="red"
                                offset-x="10"
                                offset-y="10"
                                :value="notifications?.length > 0"
                                :content="notifications?.length"
                            >
                                <v-icon>mdi-bell</v-icon>
                            </v-badge>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Notifications</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        @click="logout()"
                        >
                        <v-list-item-icon>
                            <v-icon>mdi-power</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>Logout</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    
        <v-app-bar app color="indigo" class="darken-4" dark>
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
    
            <v-toolbar-title>Application</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-avatar color="indigo" class="darken-2 pointer" size="40">
                <span class="white--text">{{ $store.getters.getUser.name.substring(0, 1) }}</span>
            </v-avatar>
        </v-app-bar>
    
        <v-main>
            <router-view></router-view>
        </v-main>
    </div>
</template>
<style scoped>
    .pointer {
        cursor: pointer;
    }
</style>
<script>
import axios from 'axios';
import { mapState } from 'vuex';
export default {
    mounted() {
        this.page = 1;
        this.$store.dispatch('fetchNotifications');
    },
    data() {
        return {
            drawer: null,
            page : 0,
            menus : [
                {
                    icon : 'mdi-monitor-dashboard',
                    text : 'Dashboard',
                    link : 'Overview'
                },
                {
                    icon : 'mdi-tray-full',
                    text : 'Jobs',
                    link : 'Jobs'
                },
                {
                    icon : 'mdi-account',
                    text : 'Profile',
                    link : 'Profile'
                }
            ]
        }
    },
    computed: {
        ...mapState({
            notifications: state => state.notifications.filter(n => {
                return n.has_read === 0;
            }) // Map notifications state
        })
    },
    methods: {
        redirect(link) {
            this.$router.push({name : link});
        },
        logout() {
            axios({
                url : this.$store.state.api_url + '/logout',
                method : 'POST',
                headers : {
                    'Authorization' : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                this.$router.push({name : 'Login'});
            }).catch(err => {
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                this.$router.push({name : 'Login'});
            });
        }
    }
}
</script>