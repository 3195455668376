export const globalMixins = {
    data() {
        return {
            priorities : [
                'Top (COB)',
                'High (1 day)',
                'Standard (2 days)',
                'Standard (3 days)',
                'Standard (4 days)',
                'Low (5 days)',
                'Low (6 days)',
                'Low (7 days)',
            ],
            bph_priorities : [
                'Top (COB)',
                'Standard (1 day)',
                'Standard (2 days)',
            ],
            lbs_headers : [
                {
                    text: 'Submission Date',
                    align: 'start',
                    sortable: true,
                    value: 'date_submitted',
                },
                {
                    text: 'Priority',
                    align: 'start',
                    sortable: true,
                    value: 'priority',
                },
                {
                    text: 'Job Type',
                    align: 'start',
                    sortable: true,
                    value: 'job_type',
                },
                {
                    text: 'Ref #',
                    align: 'start',
                    sortable: true,
                    value: 'lbs_ref_no',
                },
                {
                    text: 'Client Ref #',
                    align: 'start',
                    sortable: true,
                    value: 'client_ref_no',
                },
                {
                    text: 'Client',
                    align: 'start',
                    sortable: true,
                    value: 'client',
                },
                {
                    text: 'Submitted By',
                    align: 'start',
                    sortable: true,
                    value: 'company_name',
                },  
                {
                    text: 'Job Status',
                    align: 'start',
                    sortable: true,
                    value: 'job_status',
                },
                {
                    text: 'Staff',
                    align: 'start',
                    sortable: true,
                    value: 'staff',
                },
                {
                    text: 'Est. Completion',
                    align: 'start',
                    sortable: true,
                    value: 'estimated_completion_date',
                },
                {
                    text: 'Details',
                    align: 'start',
                }
            ],
            bph_headers : [
                {
                    text: 'Submission Date',
                    align: 'start',
                    sortable: true,
                    value: 'date_submitted',
                },
                {
                    text: 'Priority',
                    align: 'start',
                    sortable: true,
                    value: 'priority',
                },
                {
                    text: 'NCC',
                    align: 'start',
                    sortable: true,
                    value: 'version',
                },
                {
                    text: 'Job Request Type',
                    align: 'start',
                    sortable: true,
                    value: 'job_type',
                },
                {
                    text: 'Job Number',
                    align: 'start',
                    sortable: true,
                    value: 'job_number',
                },
                {
                    text: 'Client',
                    align: 'start',
                    sortable: true,
                    value: 'company_name',
                },  
                {
                    text: 'Star Rating',
                    align: 'start',
                    sortable: true,
                    value: 'star_rating',
                },
                {
                    text: 'Climate Zone',
                    align: 'start',
                    sortable: true,
                    value: 'climate_zone',
                },
                {
                    text: 'Job Address',
                    align: 'start',
                    sortable: true,
                    value: 'job_address',
                },
                {
                    text: 'Staff',
                    align: 'start',
                    align: 'staff',
                },
                {
                    text: 'Job Status',
                    align: 'start',
                    align: 'job_status',
                },
                {
                    text: 'Details',
                    align: 'start',
                    align: 'job_status',
                }
            ],
            regions : [
                {"id":"1 - Darwin", "value":"1 - Darwin"},
                {"id":"2 - Port Hedland", "value":"2 - Port Hedland"}, 
                {"id":"3 - Longreach", "value":"3 - Longreach"},
                {"id":"4 - Carnarvon", "value":"4 - Carnarvon"},
                {"id":"5 - Townsville", "value":"5 - Townsville"},
                {"id":"6 - Alice Springs", "value":"6 - Alice Springs"},
                {"id":"7 - Rockhampton", "value":"7 - Rockhampton"},
                {"id":"8 - Moree", "value":"8 - Moree"},
                {"id":"9 - Amberly", "value":"9 - Amberly"},
                {"id":"10 - Brisbane", "value":"10 - Brisbane"},
                {"id":"11 - Coffs Harbour", "value":"11 - Coffs Harbour"},
                {"id":"12 - Geraldton", "value":"12 - Geraldton"},
                {"id":"13 - Perth", "value":"13 - Perth"},
                {"id":"14 - Armidale", "value":"14 - Armidale"},
                {"id":"15 - Williamtown", "value":"15 - Williamtown"},
                {"id":"16 - Adelaide", "value":"16 - Adelaide"},
                {"id":"17 - Sydney East", "value":"17 - Sydney East"},
                {"id":"18 - Nowra", "value":"18 - Nowra"},
                {"id":"19 - Charleville", "value":"19 - Charleville"},
                {"id":"20 - Wagga", "value":"20 - Wagga"},
                {"id":"21 - Melbourne", "value":"21 - Melbourne"},
                {"id":"22 - East Sale", "value":"22 - East Sale"},
                {"id":"23 - Launceston", "value":"23 - Launceston"},
                {"id":"24 - Canberra", "value":"24 - Canberra"},
                {"id":"25 - Cabramurra", "value":"25 - Cabramurra"},
                {"id":"26 - Hobart", "value":"26 - Hobart"},
                {"id":"27 - Mildura", "value":"27 - Mildura"},
                {"id":"28 - Richmond (NSW)", "value":"28 - Richmond (NSW)"},
                {"id":"29 - Weipa", "value":"29 - Weipa"},
                {"id":"30 - Wyndham", "value":"30 - Wyndham"},
                {"id":"31 - Willis Island", "value":"31 - Willis Island"},
                {"id":"32 - Cairns", "value":"32 - Cairns"},
                {"id":"33 - Broome", "value":"33 - Broome"},
                {"id":"34 - Learmouth", "value":"34 - Learmouth"},
                {"id":"35 - Mackay", "value":"35 - Mackay"},
                {"id":"36 - Gladstone", "value":"36 - Gladstone"},
                {"id":"37 - Halls Creek", "value":"37 - Halls Creek"},
                {"id":"38 - Tennant Creek", "value":"38 - Tennant Creek"},
                {"id":"39 - Mt Isa", "value":"39 - Mt Isa"},
                {"id":"40 - Newman", "value":"40 - Newman"},
                {"id":"41 - Giles", "value":"41 - Giles"},
                {"id":"42 - Meekatharra", "value":"42 - Meekatharra"},
                {"id":"44 - Kalgoorlie", "value":"44 - Kalgoorlie"},
                {"id":"45 - Woomera", "value":"45 - Woomera"},
                {"id":"46 - Cobar", "value":"46 - Cobar"},
                {"id":"47 - Bickley", "value":"47 - Bickley"},
                {"id":"48 - Dubbo", "value":"48 - Dubbo"},
                {"id":"49 - Katanning", "value":"49 - Katanning"},
                {"id":"50 - Oakey", "value":"50 - Oakey"},
                {"id":"51 - Forrest", "value":"51 - Forrest"},
                {"id":"52 - Swanbourne", "value":"52 - Swanbourne"},
                {"id":"53 - Ceduna", "value":"53 - Ceduna"},
                {"id":"54 - Mandurah", "value":"54 - Mandurah"},
                {"id":"55 - Esperance", "value":"55 - Esperance"},
                {"id":"56 - Mascot", "value":"56 - Mascot"},
                {"id":"57 - Manjimup", "value":"57 - Manjimup"},
                {"id":"58 - Albany", "value":"58 - Albany"},
                {"id":"59 - Mt Lofty", "value":"59 - Mt Lofty"},
                {"id":"60 - Tullamarine", "value":"60 - Tullamarine"},
                {"id":"61 - Mt Gambier", "value":"61 - Mt Gambier"},
                {"id":"62 - Moorabbin", "value":"62 - Moorabbin"},
                {"id":"63 - Warnambool", "value":"63 - Warnambool"},
                {"id":"64 - Cape Otway", "value":"64 - Cape Otway"},
                {"id":"65 - Orange", "value":"65 - Orange"},
                {"id":"66 - Ballarat", "value":"66 - Ballarat"},
                {"id":"67 - Low Head", "value":"67 - Low Head"},
                {"id":"68 - Launceston Air", "value":"68 - Launceston Air"},
                {"id":"69 - Thredbo", "value":"69 - Thredbo"},
            ]
        }
    },
    methods : {
        redirect(page) {
            this.$router.push({name : page});
        },
        getJobType(id) {
            if(id) {
                return this.job_types[id - 1].type || null;
            }
        },
        formatDate(date) {
            let year = date.getFullYear();
            let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
            let day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        openLink(section, id, type, file) {
            if(type == 'link') {
                window.open(file, '_blank');
            }
            else {
                window.open(this.$store.state.host_url + '/uploads/' + section + '/' + id + '/' + file, '_blank');
            }
        }
    }
}