<template>
    <div class="pa-5">
        <v-alert
            icon="mdi-information-outline"
            prominent
            text
            type="info"
            v-if="file_groups.length == 0"
            >
            No Records Found
        </v-alert>
        <v-row>
            <v-col cols="12" sm="6" md="4" v-for="g in file_groups" :key="g.id">
                <v-card color="indigo" class="darken-4">
                    <v-card-title class="white--text">
                        {{ g.name }}
                    </v-card-title>
                    <v-card-subtitle class="white--text">
                        {{ g.description || 'No description' }}
                    </v-card-subtitle>
                    <v-divider></v-divider>
                    <v-list dense>
                        <v-list-item v-for="f in g.files" :key="f.id" @click="viewDetails(f)">
                            <v-list-item-title>
                                {{ f.name }}
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="g.files.length == 0">
                            <v-list-item-title>No files found</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog width="500" v-model="view_file_details_dialog">
            <v-card>
                <v-list>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Name</v-list-item-subtitle>
                            <v-list-item-title>{{ file.name }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Description</v-list-item-subtitle>
                            <v-list-item-title>{{ file.description }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Link</v-list-item-subtitle>
                            <v-list-item-title>
                                <a :href="file.file_link" target="_blank">{{ file.file_link }}</a>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    name : 'DashboardView',
    data() {
        return {
            file : {},
            file_groups: [],
            view_file_details_dialog : false
        }
    },
    mounted() {
        this.refreshFiles();
    },
    methods: {
        refreshFiles() {
            axios({
                url : process.env.VUE_APP_API_URL + '/file-directory/file-groups',
                method : 'GET',
                headers : {
                    'Authorization' : 'Bearer ' + this.$store.getters.getToken
                }
            }).then(res => {
                let output = res.data;
                this.file_groups = output.data;
            })
        },
        viewDetails(file) {
            this.file = file;
            console.log(file);
            this.view_file_details_dialog = true;
        }
    },
}
</script>