import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pa-5"},[(_vm.file_groups.length == 0)?_c(VAlert,{attrs:{"icon":"mdi-information-outline","prominent":"","text":"","type":"info"}},[_vm._v(" No Records Found ")]):_vm._e(),_c(VRow,_vm._l((_vm.file_groups),function(g){return _c(VCol,{key:g.id,attrs:{"cols":"12","sm":"6","md":"4"}},[_c(VCard,{staticClass:"darken-4",attrs:{"color":"indigo"}},[_c(VCardTitle,{staticClass:"white--text"},[_vm._v(" "+_vm._s(g.name)+" ")]),_c(VCardSubtitle,{staticClass:"white--text"},[_vm._v(" "+_vm._s(g.description || 'No description')+" ")]),_c(VDivider),_c(VList,{attrs:{"dense":""}},[_vm._l((g.files),function(f){return _c(VListItem,{key:f.id,on:{"click":function($event){return _vm.viewDetails(f)}}},[_c(VListItemTitle,[_vm._v(" "+_vm._s(f.name)+" ")])],1)}),(g.files.length == 0)?_c(VListItem,[_c(VListItemTitle,[_vm._v("No files found")])],1):_vm._e()],2)],1)],1)}),1),_c(VDialog,{attrs:{"width":"500"},model:{value:(_vm.view_file_details_dialog),callback:function ($$v) {_vm.view_file_details_dialog=$$v},expression:"view_file_details_dialog"}},[_c(VCard,[_c(VList,[_c(VListItem,[_c(VListItemContent,[_c(VListItemSubtitle,[_vm._v("Name")]),_c(VListItemTitle,[_vm._v(_vm._s(_vm.file.name))])],1)],1),_c(VListItem,[_c(VListItemContent,[_c(VListItemSubtitle,[_vm._v("Description")]),_c(VListItemTitle,[_vm._v(_vm._s(_vm.file.description))])],1)],1),_c(VListItem,[_c(VListItemContent,[_c(VListItemSubtitle,[_vm._v("Link")]),_c(VListItemTitle,[_c('a',{attrs:{"href":_vm.file.file_link,"target":"_blank"}},[_vm._v(_vm._s(_vm.file.file_link))])])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }